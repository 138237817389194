import React, { useState, useEffect } from 'react';
import {
    Paper,
    Chip,
    Stack,
    Typography,
    IconButton,
    CircularProgress,
    Alert,
    Button,
    Box,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { useUserContext } from '../../auth/AuthProvider';
import { getUserResearchInterests } from '../../services/researchInterestServices'; // Ensure this path is correct
import { motion } from 'framer-motion';
import { Icon } from '@iconify/react/dist/iconify.js';
import PublonsIcon from '../../assets/publons.png';
const ResearchInterestPage = () => {
    const { token, user } = useUserContext();
    const { userId } = useParams();
    const [interestsList, setInterestsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Fetch user's research interests
    useEffect(() => {
        const fetchResearchInterests = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await getUserResearchInterests(token, userId);
                setInterestsList(data.map(item => item.interest)); // Adjust based on your API response
            } catch (error) {
                setError('Failed to fetch research interests.');
            } finally {
                setLoading(false);
            }
        };

        fetchResearchInterests();
    }, [token, userId]);

    return (
        <Paper sx={{ p: 4, borderRadius: 2, boxShadow: 3 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <Stack direction="row" spacing={0.5} color="primary.main" alignItems="center">
                    <Icon width={20} height={20} icon="material-symbols:lab-research" />
                    <Typography variant="h5" fontWeight="bold">
                        Research Interests
                    </Typography>
                </Stack>

                {user?.id === userId && (
                    <Link to={`/research-interest-edit`} style={{ textDecoration: 'none' }}>
                        <Button variant="contained" startIcon={<Add />}>
                            Add Research Interest
                        </Button>
                    </Link>
                )}
            </Stack>

            {loading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}

            <Box mt={2}>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                    {interestsList.length === 0 ? (
                        <Typography variant="body1" color="text.secondary">
                            No research interests added yet.
                        </Typography>
                    ) : (
                        interestsList.map((interestItem) => (
                            <motion.div
                                key={interestItem}
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                transition={{ duration: 0.3 }}
                            >
                                <Chip
                                    label={interestItem}
                                    sx={{
                                        bgcolor: 'primary.main',
                                        color: 'white',
                                        '&:hover': {
                                            bgcolor: 'primary.dark',
                                            cursor: 'pointer',
                                        },
                                        '&:focus': {
                                            outline: 'none',
                                            boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.3)',
                                        },
                                    }}
                                />
                            </motion.div>
                        ))
                    )}
                </Stack>
            </Box>
            <Stack direction="row" alignItems="center" mt={3}>
                <img width={40} height={40} src={PublonsIcon}/>
                <Typography color="primary" variant="h5" fontWeight="bold" >
                    Web of science categories
                </Typography>
            </Stack>

        </Paper>
    );
};

export default ResearchInterestPage;
